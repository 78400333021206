import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/AutoDocLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Name`}</h2>
    <p><strong parentName="p">{`step ca policy authority x509 deny ip`}</strong>{` -- add or remove ip addresses`}</p>
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-raw"
      }}>{`step ca policy authority x509 deny ip <ip address> [--remove]
[--provisioner=<name>] [--eab-key-id=<eab-key-id>] [--eab-key-reference=<eab-key-reference>]
[--admin-cert=<file>] [--admin-key=<file>] [--admin-subject=<subject>]
[--admin-provisioner=<name>] [--admin-password-file=<file>]
[--ca-url=<uri>] [--root=<file>] [--context=<name>]
`}</code></pre>
    <h2>{`Description`}</h2>
    <p><strong parentName="p">{`step ca policy authority x509 deny ip`}</strong>{` command manages IP addresses and ranges in policies`}</p>
    <h2>{`Options`}</h2>
    <p><strong parentName="p">{`--provisioner`}</strong>{`=`}<inlineCode parentName="p">{`name`}</inlineCode>{`
The provisioner `}<inlineCode parentName="p">{`name`}</inlineCode></p>
    <p><strong parentName="p">{`--eab-key-id`}</strong>{`=`}<inlineCode parentName="p">{`value`}</inlineCode>{`
An ACME EAB Key ID.`}</p>
    <p><strong parentName="p">{`--eab-key-reference`}</strong>{`=`}<inlineCode parentName="p">{`value`}</inlineCode>{`
An ACME EAB Key Reference.`}</p>
    <p><strong parentName="p">{`--remove`}</strong>{`
removes the provided IPs from the policy instead of adding them`}</p>
    <p><strong parentName="p">{`--admin-cert`}</strong>{`=`}<inlineCode parentName="p">{`chain`}</inlineCode>{`
Admin certificate (`}<inlineCode parentName="p">{`chain`}</inlineCode>{`) in PEM format to store in the 'x5c' header of a JWT.`}</p>
    <p><strong parentName="p">{`--admin-key`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
Private key `}<inlineCode parentName="p">{`file`}</inlineCode>{`, used to sign a JWT, corresponding to the admin certificate that will
be stored in the 'x5c' header.`}</p>
    <p><strong parentName="p">{`--admin-subject`}</strong>{`=`}<inlineCode parentName="p">{`subject`}</inlineCode>{`, `}<strong parentName="p">{`--admin-name`}</strong>{`=`}<inlineCode parentName="p">{`subject`}</inlineCode>{`
The admin `}<inlineCode parentName="p">{`subject`}</inlineCode>{` to use for generating admin credentials.`}</p>
    <p><strong parentName="p">{`--admin-provisioner`}</strong>{`=`}<inlineCode parentName="p">{`name`}</inlineCode>{`, `}<strong parentName="p">{`--admin-issuer`}</strong>{`=`}<inlineCode parentName="p">{`name`}</inlineCode>{`
The provisioner `}<inlineCode parentName="p">{`name`}</inlineCode>{` to use for generating admin credentials.`}</p>
    <p><strong parentName="p">{`--admin-password-file`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`, `}<strong parentName="p">{`--password-file`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The path to the `}<inlineCode parentName="p">{`file`}</inlineCode>{` containing the password to decrypt the one-time token
generating key.`}</p>
    <p><strong parentName="p">{`--ca-url`}</strong>{`=`}<inlineCode parentName="p">{`URI`}</inlineCode>{`
`}<inlineCode parentName="p">{`URI`}</inlineCode>{` of the targeted Step Certificate Authority.`}</p>
    <p><strong parentName="p">{`--root`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The path to the PEM `}<inlineCode parentName="p">{`file`}</inlineCode>{` used as the root certificate authority.`}</p>
    <p><strong parentName="p">{`--context`}</strong>{`=`}<inlineCode parentName="p">{`name`}</inlineCode>{`
The context `}<inlineCode parentName="p">{`name`}</inlineCode>{` to apply for the given command.`}</p>
    <h2>{`Examples`}</h2>
    <p>{`Allow IP address 127.0.0.1 in X.509 certificates on authority level`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ca policy authority x509 allow ip 127.0.0.1
`}</code></pre>
    <p>{`Allow IP address range 10.0.0.0/24 in X.509 certificates on authority level`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ca policy authority x509 allow ip 10.0.0.0/24
`}</code></pre>
    <p>{`Deny IP address 10.0.0.30 in X.509 certificates on authority level`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ca policy authority x509 deny ip 10.0.0.30
`}</code></pre>
    <p>{`Remove IP address range 10.0.0.0/24 from being allowed in X.509 certificates on authority level`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ca policy authority x509 allow ip 10.0.0.0/24 --remove
`}</code></pre>
    <p>{`Allow IP address range 10.10.0.0/24 in X.509 certificates on provisioner level`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ca policy provisioner x509 allow ip 10.10.0.0/24 --provisioner my_provisioner
`}</code></pre>
    <p>{`Deny IP address 10.10.0.50 in X.509 certificates on provisioner level`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ca policy provisioner x509 deny ip 10.10.0.50 --provisioner my_provisioner
`}</code></pre>
    <p>{`Remove IP address 10.10.0.50 from being denied in X.509 certificates on provisioner level`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ca policy provisioner x509 deny ip 10.10.0.50 --provisioner my_provisioner --remove
`}</code></pre>
    <p>{`Allow IP address range 10.20.0.0/24 in X.509 certificates on ACME account level by EAB key reference`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ca policy provisioner x509 allow ip 10.10.0.0/24 --provisioner my_acme_provisioner --eab-key-reference my_ref
`}</code></pre>
    <p>{`Deny IP address 10.20.0.70 in X.509 certificates on ACME account level by EAB key reference`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ca policy provisioner x509 deny ip 10.20.0.70 --provisioner my_acme_provisioner --eab-key-reference my_ref
`}</code></pre>
    <p>{`Remove IP address 10.20.0.70 from being denied in X.509 certificates on ACME account level by EAB key reference`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ca policy provisioner x509 deny ip 10.20.0.70 --provisioner my_acme_provisioner --eab-key-reference my_ref --remove
`}</code></pre>
    <p>{`Allow IP address range 192.168.0.0/24 in SSH host certificates on authority level`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ca policy authority ssh host allow ip 192.168.0.0/24
`}</code></pre>
    <p>{`Deny IP address 192.168.0.40 in SSH host certificates on authority level`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ca policy authority ssh host deny ip 192.168.0.40
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      